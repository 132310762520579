<template>
    <ContentLoader
        primary-color="#f6f7f8"
        secondary-color="#eef0f2"
        width="100"
        height="60"
        preserveAspectRatio="none"
        :speed="1"
    >
        <rect
            x="0"
            y="0"
            rx="1"
            ry="1"
            width="20"
            height="4"
        />
        <rect
            x="0"
            y="7"
            rx="6"
            ry="6"
            width="6"
            height="6"
        />
        <rect
            x="10"
            y="7"
            rx="1"
            ry="1"
            width="30"
            height="3"
        />
        <rect
            x="10"
            y="11"
            rx="1"
            ry="1"
            width="10"
            height="2"
        />
        <rect
            x="10"
            y="15"
            rx="1"
            ry="1"
            width="100"
            height="5"
        />
        <rect
            x="0"
            y="24"
            rx="6"
            ry="6"
            width="6"
            height="6"
        />
        <rect
            x="10"
            y="24"
            rx="1"
            ry="1"
            width="30"
            height="3"
        />
        <rect
            x="10"
            y="29"
            rx="1"
            ry="1"
            width="10"
            height="2"
        />
        <rect
            x="10"
            y="33"
            rx="1"
            ry="1"
            width="100"
            height="5"
        />
        <rect
            x="0"
            y="42"
            rx="6"
            ry="6"
            width="6"
            height="6"
        />
        <rect
            x="10"
            y="42"
            rx="1"
            ry="1"
            width="30"
            height="3"
        />
        <rect
            x="10"
            y="47"
            rx="1"
            ry="1"
            width="10"
            height="2"
        />
        <rect
            x="10"
            y="51"
            rx="1"
            ry="1"
            width="100"
            height="5"
        />
    </ContentLoader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';

    export default {
        name:'ProductQALoader',
        components: {
            ContentLoader,
        },
    };
</script>