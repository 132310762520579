<template>
    <div class="pagination">
        <div class="left-pagination">
            <caption>
                {{
                    $t('page.pagination.label')
                }}
            </caption>
            <input
                v-model.number="pageNumber"
                type="number"
                name="currentPage"
                min="1"
                :max="lastPage"
                @keyup.enter="goToPage($event)"
                @change="goToPage($event)"
            >
            <caption>
                {{
                    fromLabel
                }}
            </caption>
        </div>
        <div class="right-pagination">
            <b-button
                v-if="currentPage > 1"
                variant="primary"
                class="btn-prev icon stroke sm"
                @click="showPrev"
            >
                <chevron-left-icon /><span>{{ $t('page.pagination.previous') }}</span>
            </b-button>
            <b-button
                v-if="currentPage < lastPage"
                variant="primary"
                class="btn-next icon fill sm"
                @click="showNext"
            >
                <span>{{ $t('page.pagination.next') }}</span><chevron-right-icon />
            </b-button>
        </div>
    </div>
</template>

<script>
    import ChevronLeftIcon from 'vue-feather-icons/icons/ChevronLeftIcon';
    import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions } from 'vuex';
    export default {
        name: 'ProductPagination',
        components: {
            ChevronRightIcon,
            ChevronLeftIcon,
        },
        props: {
            page: {
                type: Object,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                query: {
                    page: 1,
                },
                pageNumber: 1,
            };
        },
        computed: {
            currentPage() {
                return this.page.currentPage;
            },
            lastPage() {
                return this.page.lastPage;
            },
            fromLabel() {
                if (i18n.locale === 'ro') {
                    return this.$t('page.pagination.total') + ' ' + this.page.lastPage;
                }
                return this.page.lastPage + ' ' + this.$t('page.pagination.total');
            },
        },
        methods: {
            ...mapActions('product', ['getReviews', 'getQuestionsAndAnswers']),
            showNext() {
                ++this.query.page;
                if (this.type == 'reviews') {
                    this.getReviews(this.query);
                } else {
                    this.getQuestionsAndAnswers(this.query);
                }
                this.scrollToAnchorPoint(this.type);
            },
            showPrev() {
                --this.query.page;
                if (this.type == 'reviews') {
                    this.getReviews(this.query);
                } else {
                    this.getQuestionsAndAnswers(this.query);
                }
                this.scrollToAnchorPoint(this.type);
            },
            updatePagination() {
                this.query.page = this.page.currentPage;
                this.pageNumber = this.page.currentPage;
            },
            scrollToAnchorPoint(id) {
                const el = document.getElementById(id);
                window.scrollTo(0, el.offsetTop - 100);
            },
            goToPage($event) {
                if (/^[1-9]\d*$|^$/.test($event.target.value) && $event.target.value) {
                    if (
                        $event.target.value >= 1 &&
                        $event.target.value <= this.page.lastPage
                    ) {
                        this.query.page = $event.target.value;
                        if (this.type == 'reviews') {
                            this.getReviews(this.query);
                        } else {
                            this.getQuestionsAndAnswers(this.query);
                        }
                        this.scrollToAnchorPoint(this.type);
                    }
                }
            },
        },
        watch: {
            page() {
                this.updatePagination();
            },
        },
    };
</script>
