<template>
    <div class="questions-and-answers-container">
        <div
            class="qA d-flex"
            :class="{ 'mt-4': !subQuestion }"
        >
            <div class="avatar">
                <img
                    :src="item.user_avatar ? item.user_avatar : '/images/admin.webp'"
                    alt="avatar"
                    width="32"
                    height="32"
                >
            </div>
            <div class="item-content">
                <div class="question-wrapper">
                    <div class="heading d-flex column">
                        <p class="sbold">
                            {{ item.user_name }}
                        </p>
                        <p class="sm date">
                            {{ item.created_at }}
                        </p>
                    </div>
                    <div class="message">
                        <p class="sbold">
                            {{ getQuestion(item) }}
                        </p>
                        <b-button
                            v-if="!subQuestion"
                            variant="text"
                            class="link sm answer"
                            @click="openQAModal(item.id)"
                        >
                            <img
                                src="/images/progress-question.svg"
                                :alt="$t('page.product.addAnswerButton')"
                                width="16"
                                height="16"
                            ><span>{{ $t('page.product.addAnswerButton') }}</span>
                        </b-button>
                    </div>
                </div>
                <hr v-if="!subQuestion">
            </div>
        </div>
        <div
            v-if="item.answer"
            class="answer-wrapper qA d-flex"
            :class="{ 'mt-2': !subQuestion }"
        >
            <div class="avatar">
                <img
                    src="/images/admin.webp"
                    alt="avatar"
                    width="32"
                    height="32"
                >
            </div>
            <div
                v-if="item.answer"
                class="item-content"
            >
                <div class="heading d-flex column">
                    <p class="sbold primary">
                        Admin
                    </p>
                    <p class="sm date">
                        {{ item.answered_at }}
                    </p>
                </div>
                <div class="message">
                    <p class="sbold">
                        {{ getAnswer(item) }}
                    </p>
                </div>
            </div>
        </div>
        <div class="sub-question">
            <questions-and-answers-item
                v-for="question in item.children"
                :key="`q&a-${item.children.indexOf(question)}`"
                :item="question"
                :sub-question="true"
            />
        </div>
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapMutations } from 'vuex';
    export default {
        name: 'QuestionsAndAnswersItem',
        props: {
            item: {
                type: Object,
                default() {
                    return {};
                },
            },
            subQuestion: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            ...mapMutations('product', ['setQAAParent', 'setModal']),
            openQAModal(parent) {
                this.setQAAParent(parent);
                this.setModal({
                    show: true,
                    type: 'qaa',
                });
            },
            getQuestion(item) {
                return item.question[i18n.locale];
            },
            getAnswer(item) {
                return item.answer[i18n.locale];
            },
        },
    };
</script>
<style scoped lang="scss">
.qA {
  gap: 18px;
  .avatar {
    img {
      border-radius: 32px;
    }
  }
  .item-content {
    flex: 1;
    .heading {
      align-items: flex-start;
      gap: 0;
      p {
        color: $text;
        &.date {
          font-size: 11px;
          line-height: 13px;
          color: $gray-dark;
        }
      }
    }
    .message {
      margin-top: 10px;
      p {
        color: $text;
      }
      .answer {
        padding: 0;
        margin-top: 8px;
        color: $primary;
        img {
          margin-right: 4px;
        }
        @include media-breakpoint-up(md) {
          &:hover {
            background: none;
            color: $primary-dark;
          }
        }
      }
    }
  }
  hr {
    color: $gray-light;
  }
  &.answer-wrapper {
    padding-left: 50px;
    .heading {
      p {
        color: $primary;
      }
    }
  }
}
.sub-question {
  padding-left: 50px;
  .qA {
    margin-top: 14px;
  }
  .answer-wrapper {
    margin-top: 14px;
    padding: 0;
  }
}
</style>
