<template>
    <b-row
        id="questions"
        class="questionsAndAnswers mt-40"
    >
        <b-col
            v-if="existData"
            cols="12"
        >
            <div class="heading d-flex">
                <h5 class="title">
                    {{ $t('page.product.qAHeading') }}
                </h5>
                <p
                    v-if="existQA"
                    class="sbold"
                >
                    ({{ totalQuestionsAndAnswers }})
                </p>
            </div>
            <div class="content">
                <b-button
                    variant="primary"
                    class="fill sm"
                    @click="openQAModal()"
                >
                    {{ $t('page.product.addQuestionButton') }}
                </b-button>
            </div>
            <questions-and-answers-item
                v-for="question in questionsAndAnswers.elements"
                :key="`q&a-${questionsAndAnswers.elements.indexOf(question)}`"
                :item="question"
                :sub-question="false"
            />
            <questions-pagination
                v-if="multiplePages"
                :page="questionsAndAnswers.page"
                type="questions"
            />
        </b-col>
        <product-q-a-loader v-if="loading && !existData" />
        <add-question-modal v-if="showModal" />
    </b-row>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    import ProductQALoader from '@/components/loaders/product/ProductQALoader';

    import QuestionsPagination from './ProductPagination';
    import QuestionsAndAnswersItem from './QuestionsAndAnswersItem';

    const AddQuestionModal = () => import('@/components/modals/AddQuestionModal');

    export default {
        components: {
            QuestionsAndAnswersItem,
            QuestionsPagination,
            ProductQALoader,
            AddQuestionModal,
        },
        name: 'QuestionsAndAnswersListing',
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
            product: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState('product', ['questionsAndAnswers']),
            ...mapGetters('auth', ['isLogged']),
            totalQuestionsAndAnswers() {
                let number = this.questionsAndAnswers.page.total;

                return number > 1
                    ? this.$t('page.product.multipleQuestionText', { number })
                    : this.$t('page.product.singleQuestionText', { number });
            },
            multiplePages() {
                return this.questionsAndAnswers.page.lastPage > 1;
            },
            existData() {
                return Object.keys(this.questionsAndAnswers).length > 0;
            },
            existQA(){
                return this.questionsAndAnswers.elements && Object.keys(this.questionsAndAnswers.elements).length > 0;
            }
        },
        methods: {
            ...mapMutations('product', ['setQAAParent', 'setModal']),
            ...mapMutations('general', { setGeneralModal: 'setModal' }),
            ...mapActions('product', ['getQuestionsAndAnswers']),
            async loadQuestionsAndAnswers() {
                this.loading = true;
                await this.getQuestionsAndAnswers(null);
                this.loading = false;
            },
            openQAModal(parent = null) {
                if (!this.isLogged) {
                    this.setGeneralModal({
                        show: true,
                        type: 'login',
                    });
                    return;
                }
                this.setQAAParent(parent);
                this.setModal({
                    show: true,
                    type: 'qaa',
                });
            },
        },
        watch: {
            product: {
                handler() {
                    this.loadQuestionsAndAnswers();
                },
                deep: true,
            },
        },
    };
</script>
<style scoped lang="scss">
.heading {
  p {
    color: $gray-dark;
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    row-gap: 0;
  }
}
.content {
  p {
    color: $gray-dark;
  }
  .btn {
    margin-top: 8px;
  }
}
</style>
